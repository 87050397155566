<template>
  <div class="settings">
    <div class="container" v-if="!isLoading">
      <div class="settings__title">
        <h3>{{$t('settings.banner_settings')}}</h3>
      </div>
      <div class="box mg-t-30 pd-25 with-red-top">
        <div class="flex align-center justify-between settings__head mg-b-15">
          <h4>{{$t('settings.banner')}}</h4>
          <button class="btn btn--green" @click="openEdit"><i class="fas fa-pen"/> {{$t('global.edit')}}</button>
        </div>
        <div class="title-gray mg-b-5">{{$t('settings.banner_title')}}</div>
        <div class="text-dark">{{settings.title || ''}}</div>
        <div class="title-gray mg-b-5 mg-t-15">{{$t('settings.banner_text')}}</div>
        <div class="text-dark">{{settings.text || ''}}</div>
        <div class="title-gray mg-b-5 mg-t-15">{{$t('settings.banner_details')}}</div>
        <div class="text-dark">{{settings.detail || ''}}</div>
        <div class="mg-t-20">
          <img v-for="(image, index) in settings.images" :src="image" :key="index" class="uploaded-cont"/>
        </div>
      </div>
    </div>

    <vue-final-modal
      v-model="showEdit"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="modal-close" @click="showEdit = false">
        <i class="fas fa-times"/>
      </div>
      <div class="modal-title">{{$t('global.edit')}}</div>
      <form class="modal-page" @submit.prevent="onSubmit">
        <div class="title-gray mg-b-5">{{$t('settings.banner_title')}}</div>
        <div class="form-element mg-b-20">
          <input type="text" required v-model="form.title" class="form-control"/>
        </div>
        <div class="title-gray mg-b-5">{{$t('settings.banner_text')}}</div>
        <div class="form-element mg-b-20">
          <input type="text" required v-model="form.text" class="form-control"/>
        </div>
        <div class="title-gray mg-b-5">{{$t('settings.banner_details')}}</div>
        <div class="form-element mg-b-20">
          <textarea class="form-control" required v-model="form.detail"/>
        </div>
        <image-select :default-value="form.images || []" @selected="imagesChange"/>
        <div class="flex align-center justify-center">
          <button class="btn btn--red w-150">{{$t('global.save')}}</button>
        </div>
      </form>
      <loading v-if="isSaving"/>
    </vue-final-modal>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import { Settings } from '@/api/api'
import ImageSelect from '@/components/imageSelect/imageSelect'
export default {
  name: 'BannerSettings',
  components: {
    ImageSelect,
    Loading
  },
  data () {
    return {
      setId: 0,
      settings: {},
      form: {},
      first_time_user_discount: 0,
      all_user_dicscount: 0,
      lifes_point_conversion: 0,
      showEdit: false,
      isLoading: true,
      isSaving: false
    }
  },
  mounted () {
    this.loadSettings()
  },
  methods: {
    async loadSettings () {
      try {
        const { data } = await Settings.get()

        data.forEach((set) => {
          if (set.type === 'banner_first_time') {
            set.data.images = typeof set.data.images === 'object' ? set.data.images : [set.data.images]
            this.setId = set.id
            this.settings = { ...set.data }
            this.form = { ...set.data }
          } else {
            this[set.type] = set.value
          }
        })

        this.settings.text = this.settings.text.replace('{{first_time_user_discount}}', this.first_time_user_discount)
        this.settings.text = this.settings.text.replace('{{all_user_dicscount}}', this.all_user_dicscount)
        this.settings.text = this.settings.text.replace('{{lifes_point_conversion}}', this.lifes_point_conversion)

        this.settings.detail = this.settings.detail.replace('{{first_time_user_discount}}', this.first_time_user_discount)
        this.settings.detail = this.settings.detail.replace('{{all_user_dicscount}}', this.all_user_dicscount)
        this.settings.detail = this.settings.detail.replace('{{lifes_point_conversion}}', this.lifes_point_conversion)
      } catch (e) {
        this.$toast.error(this.$t('settings.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async onSubmit () {
      this.isSaving = true

      try {
        await Settings.update(this.setId, {
          type: 'banner_first_time',
          data: {
            ...this.form,
            images: this.form.images[0]
          }
        })

        this.settings = {
          ...this.form
        }
        this.settings.text = this.settings.text.replace('{{first_time_user_discount}}', this.first_time_user_discount)
        this.settings.text = this.settings.text.replace('{{all_user_dicscount}}', this.all_user_dicscount)
        this.settings.text = this.settings.text.replace('{{lifes_point_conversion}}', this.lifes_point_conversion)
        this.settings.detail = this.settings.detail.replace('{{first_time_user_discount}}', this.first_time_user_discount)
        this.settings.detail = this.settings.detail.replace('{{all_user_dicscount}}', this.all_user_dicscount)
        this.settings.detail = this.settings.detail.replace('{{lifes_point_conversion}}', this.lifes_point_conversion)
      } catch (err) {
        this.$toast.error(this.$t('settings.update_fail'))
      } finally {
        this.isSaving = false
        this.showEdit = false
      }
    },
    imagesChange (data) {
      this.form = {
        ...this.form,
        images: data.images
      }
    },
    openEdit () {
      this.showEdit = true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
